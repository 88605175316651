import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withdrawApplyApi, withdrawPageApi } from '../features/withdrawSlice';
import STATUSES from '../constants/reduxStatus';
import Loading from '../components/loading/Loading';
import Footer from '../components/layout/Footer';
import { addCommasToNumber } from '../utils/utils';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 13px 0px;
  text-align: center;
  @media (max-width:780px){
    padding: 40px 0px 14px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  padding: 27px 0px 16px 0px;
  border-bottom: 2px solid #666666;
  @media (max-width:780px){
    font-size: 1.125rem;
    line-height: 1.318rem;
    padding: 16px 0px 10px 0px;
    &.auth{
      padding: 40px 0px 10px 0px;
    }
  }
`

const ContentRow = styled.div`
  border-bottom: 1px solid #D9D9D9;
  display: flex;
  align-items: center;
  @media (max-width:780px){
    flex-wrap: wrap;
    padding: 12px 5px;
    &.application_cnt{
      padding: 12px 5px 9px 5px;
    }
  }
`

const ContentRowTitle = styled.div`
  padding: 25px 15px;
  min-width: 178px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  @media (max-width:780px){
    padding: 0px 0px 8px 0px;
    width: 100%;
    &.application_cnt{
      padding: 0px 0px 6px 0px;
    }
  }
`

const ContentCnt = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  display: flex;
  gap: 12px;
  @media (max-width:780px){
    width: 100%;
    gap: 8px;
  }
`

const ContentRowTxt = styled.div`
  width: 100%; 
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #222222;
  word-break: break-all;
  @media (max-width:780px){
    font-size: 0.875rem;
    line-height: 1.026rem;
    letter-spacing: -0.42px;
  }
`

const ContentInputContainer = styled.div`
  width: 100%;
  display: flex;
`

const ContentInputWrap = styled.div`
  position: relative;
  min-width: 442px;
  cursor: pointer;
  img{
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
  @media (max-width:780px){
    min-width: 100px;
    width: 100%;
  }
`

const ContentInput = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 45px;
  border: 1px solid #C4FF69;
  border-radius: 5px;
  background-color: #FFFFFFD9;
  cursor: pointer;
  &:focus{
    outline: none;
    border-color: #16BFB6;
    background: #fff;
  }
  &::placeholder{
    font-size: 1rem;
    line-height: 1.5rem;
    color: #6F6F6F;
  }
  @media (max-width:780px){
    height: 35px;
    padding: 5px 10px;
    &::placeholder{
      font-size: 0.75rem;
      line-height: 1.5rem;
      color: #6F6F6F;
    }
  }
`

const ContentInputTxt = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
  p{
    font-weight: 400;
    color: #6F6F6F;
  }
  span{
    min-width: 40px;
    font-weight: 700;
    text-align: right;
  }
  @media (max-width:780px){
    right: 10px; 
    gap: 6px; 
    font-size: 0.75rem;
    span{
      min-width: 30px;
    }
  }
`

const ContentBtn = styled.div`
  min-width: 107px;
  background-color: #16BFB6;
  border-radius: 5px;
  padding: 10px 20px;
  font-family: "NotoSansKR";
  font-weight: 700;
  color: #fff;
  font-size: 1.125rem;
  line-height: 1.5rem;
  cursor: pointer;
  margin-left: 15px;
  @media (max-width:780px){
    min-width: 65px;
    font-size: 0.75rem;
    padding: 5px 10px;
    margin-left: 8px;
  }
`

const SubmitWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0px 60px 0px;
`

const SubmitBtn = styled.div`
  background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
  color: #FFFFFF;
  border-radius: 10px;
  padding: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 460px;
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
`

const ContentSelect = styled.div`
  min-width: 442px;
  border: ${(props) => props.$isOpen ? '1px solid #C4FF69' : '1px solid #16BFB6'};
  padding: 10px 20px;
  background-color: #FFFFFFD9;
  border-radius: 5px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  img{
    width: 25px;
    height: 25px;
  }
  @media (max-width:780px){
    min-width: 100%;
    max-width: 310px;
    width: 100%;
    height: 35px;
    padding: 5px 10px;
    img{
      width: 20px;
      height: 20px;
    }
  }
`

const ContentSelectTxt = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #222222;
  @media (max-width:780px){
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
`

const ContentSelectOptionBox = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 5px 0px #66666626;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 6px;
  padding: 10px;
  border-radius: 5px;
`

const ContentSelectOption = styled.div`
  padding: 4px 10px;
  width: 100%;
  border-radius: 4px;
  &:hover{
    background-color: #16BFB626;
  }
  margin-bottom: 6px;
  &:last-child{
    margin-bottom: 0;
  }
  height: 32px; 
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #666666;
`

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: #000000A6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 475px;
  transform: translateX(-50%) translateY(-50%);
  background-color: #222222;
  border-radius: 10px;
  padding: 48px 30px 40px 30px;
  z-index: 51;
  @media (max-width:780px){
    width: 320px;
    padding: 35px 20px 30px 20px;
  }
`

const ClostBtn = styled.div`
  position: absolute;
  top: 15px;
  right: 31px;
  cursor: pointer;
  @media (max-width:780px){
    right: 12px;
  }
`

const ModalTitle = styled.div`
  font-weight: 700;
  color: #fff;
  font-size: 1.75rem;
  line-height: 2.051rem;
  text-align: center;
  @media (max-width:780px){
    font-size: 1.5rem;
    line-height: 1.758rem;
  }
`

const ModalWrap = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
  @media (max-width:780px){ 
    margin-top: 12px;
    padding: 25px 15px 15px 15px;
  }
`

const ModalContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  text-align: center;
`

const ModalContentCnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.5rem;
  line-height: 1.758rem;
  font-weight: 700;
  padding: 28px 0px 41px 0px;
  p{
    color: #16BFB6;
  }
  @media (max-width:780px){
    padding: 19px 0px 40px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ModalOtpContainer = styled.div`
  display: flex;
  padding: 34px 0px 33px 0px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  @media (max-width:780px){
    padding: 23px 0px 35px 0px;
  }
`

const ModalOtpNumberWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const ModalOtpNumber = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F1F1F1;
  &.active{
    background-color: #16BFB6;
    color: #fff;
    font-weight: 700;
    font-size: 0.938rem;
    line-height: 1.099rem;
  }
  @media (max-width:780px){
    width: 24px;
    height: 24px;
    &.active{
      font-size: 1rem;
      line-height: 1.172rem;
    }
  }
`

const ModalNumberWrap = styled.div`
  width: 355px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 8px;
  @media (max-width:780px){
    width: 250px;
    row-gap: 10px;
  }
`

const ModalNumber = styled.div`
  padding: 20px 51px 20px 52px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #F7F7F7;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 113px;
  height: 59px;
  &.reset{
    font-weight: 400;
    color: #C0C0C0;
    padding: 20px 36px;
  }
  &.backspace{
    padding: 17px 44px;
    img{
      width: 25px;
      height: 25px;
    }
  }
  @media (max-width:780px){
    padding: 13px 34px;
    width: 78px;
    height: 45px;
    &.reset{
      padding: 15px 21px 14px 21px;
      font-size: 0.875rem;
      line-height: 1.026rem;
    }
    &.backspace{
      padding: 13px 29px 12px 29px;
    }
    img{
      width: 20px;
      height: 20px;
    }
  }
`

const ModalBtn = styled.div`
  margin-top: 20px;
  background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
  border-radius: 10px;
  font-weight: 700;
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
  width: 355px;
  height: 56px; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width:780px){
    margin-top: 15px;
    width: 250px;
    height: 50px;
  }
`

function RequestWithdrawal() {

  const dispatch = useDispatch();
  const { status, error, data } = useSelector((state) => state.withdraw);

  // 구글 OTP 눈 아이콘
  const [eye, setEye] = useState([0]);
  // select 박스 값
  const [method, setMethod] = useState("ESP");
  // select 박스 open
  const [isOpen, setIsOpen] = useState(false);
  // 수량신청 모달 
  const [isCntModal, setIsCntModal] = useState(false);
  // 구글 otp 모달
  const [isOtpModal, setIsOtpModal] = useState(false);
  //모달 수량신청 수량
  const [modalCnt, setModalCnt] = useState('0');
  // 수량신청 수량
  const [cnt, setCnt] = useState('0');
  // 모달 구글 otp 코드
  const [modalOtp, setModalOtp] = useState("");
  // 구글 otp
  const [otp, setOtp] = useState("");
  // nowPoint 소수점 4자리
  const nowPoint = (data?.nowPoint ?? 0).toFixed(4);

  // 수량신청 모달 숫자 클릭
  const handleNumberClick = (number) => {
    if(modalCnt.length >= 10) {
      return;
    }
    if (modalCnt === '0') {
      setModalCnt(number);
    } else {
      const newModalCnt = modalCnt + number;
      // 숫자로 변환할 문자열 newModalCnt
      if (parseInt(newModalCnt, 10) <= 9999999999) {
        setModalCnt(newModalCnt);
      }
    }
  };

  // 수량신청 모달 backspace
  const handleBackspace = () => {
    if(modalCnt.length === 1) {
      setModalCnt('0');
    } else {
      setModalCnt(modalCnt.slice(0, -1));
    }
  };

  // 출금방법 select 박스 값 변경 핸들러
  const handleOnChangeSelectValue = (e) => {
    setMethod(e.target.getAttribute("value"));
  };

  // 구글 OTP 눈 아이콘 toggle
  const toggleEye = (index) => {
    const newEye = [...eye];
    newEye[index] = !newEye[index];
    setEye(newEye);
  };

  // 신청 최대수량 클릭시 소수점 버리고 정수
  const maxCnt = () => {
    setCnt(Math.floor(data?.nowPoint).toString());
  };

  // 신청수량 input 클릭
  const handleCntInput = () => {
    setIsCntModal(true);
    setModalCnt('0');
    setCnt('0');
    setOtp("");
  };

  // 구글 OTP input 클릭
  const handleOtpInput = () => {
    setIsOtpModal(true);
    setOtp("");
    setModalOtp("");
  };

  // 신청수량 모달 확인 버튼
  const cntModalBtn = () => {
    if(data?.nowPoint < modalCnt){
      alert("보유수량을 초과할 수 없습니다.");
      setModalCnt('0');
      return;
    }
    setIsCntModal(false);
    setCnt(modalCnt);
  };

  // 구글 otp 입력 모달 확인 버튼
  const otpModalBtn = () => {
    if(modalOtp.length < 6){
      alert("구글 OTP 코드를 정확하게 입력해 주세요.");
      return;
    }
    setIsOtpModal(false);
    setOtp(modalOtp);
  };

  // 신청하기 클릭 ( 지갑주소가 있어야만 신청하기 가능 )
  const handleSubmit = async () => {  

    if(cnt === '0'){
      alert('신청 수량을 입력해 주시길 바랍니다.');
      return;
    }

    if(otp === ""){
      alert("구글 OTP 번호를 입력해 주세요.")
      return;
    }

    if(!data?.walletAddress){
      alert("지갑 등록 후 출금 신청 가능합니다.");
      return;
    }

    try {

      const response = await dispatch(withdrawApplyApi({
        otpNumber : otp,
        amount : cnt,
        method : method
      }));

      if(response.type === 'withdraw/apply/fulfilled'){
        alert("출금신청 완료되었습니다.");
        window.location.reload();
      };
      
    } catch(error) {
      console.error('출금 신청하기 중 오류 발생', error);
    }
  };

  useEffect(()=>{
    dispatch(withdrawPageApi());
  },[dispatch]);
  
  useEffect(() => {
    document.body.style.overflow = isCntModal || isOtpModal ? 'hidden' : 'auto';
  }, [isCntModal, isOtpModal]);

  return (
    <>
    {status === STATUSES.LOADING && <Loading />}
      {
        isCntModal &&
        <>
          <ModalOverlay onClick={()=>{setIsCntModal(false)}} />
          <ModalContainer>
            <ClostBtn onClick={()=>{setIsCntModal(false)}}>
              <img src='./../img/common_close_w_btn.png' />
            </ClostBtn>
            <ModalTitle>수량신청</ModalTitle>
            <ModalWrap>
              <ModalContentTitle>출금 신청</ModalContentTitle>
              <ModalContentCnt>
                <p>{addCommasToNumber(modalCnt)}</p>
                <span>ESP</span>
              </ModalContentCnt>
              <ModalNumberWrap>
                <ModalNumber onClick={() => handleNumberClick('1')}>1</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('2')}>2</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('3')}>3</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('4')}>4</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('5')}>5</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('6')}>6</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('7')}>7</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('8')}>8</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('9')}>9</ModalNumber>
                <ModalNumber onClick={()=>{setModalCnt('0')}} className='reset'>Reset</ModalNumber>
                <ModalNumber onClick={() => handleNumberClick('0')}>0</ModalNumber>
                <ModalNumber onClick={handleBackspace} className='backspace'>
                  <img src='./../img/backspace.png' />
                </ModalNumber>
              </ModalNumberWrap>
              <ModalBtn onClick={cntModalBtn}>확인</ModalBtn>
            </ModalWrap>
          </ModalContainer>
        </>
      }
      {
        isOtpModal &&
        <>
          <ModalOverlay onClick={()=>{setIsOtpModal(false)}} />
          <ModalContainer>
            <ClostBtn onClick={()=>{setIsOtpModal(false)}}>
              <img src='./../img/common_close_w_btn.png' />
            </ClostBtn>
            <ModalTitle>구글 OTP 코드입력</ModalTitle>
            <ModalWrap>
              <ModalContentTitle>코드를 입력하세요</ModalContentTitle>
              <ModalOtpContainer>
                <ModalOtpNumberWrap>
                  <ModalOtpNumber className={`${modalOtp[0] ? 'active' : ''}`}>{modalOtp[0]}</ModalOtpNumber>
                  <ModalOtpNumber className={`${modalOtp[1] ? 'active' : ''}`}>{modalOtp[1]}</ModalOtpNumber>
                  <ModalOtpNumber className={`${modalOtp[2] ? 'active' : ''}`}>{modalOtp[2]}</ModalOtpNumber>
                </ModalOtpNumberWrap>
                <ModalOtpNumberWrap>
                  <ModalOtpNumber className={`${modalOtp[3] ? 'active' : ''}`}>{modalOtp[3]}</ModalOtpNumber>
                  <ModalOtpNumber className={`${modalOtp[4] ? 'active' : ''}`}>{modalOtp[4]}</ModalOtpNumber>
                  <ModalOtpNumber className={`${modalOtp[5] ? 'active' : ''}`}>{modalOtp[5]}</ModalOtpNumber>
                </ModalOtpNumberWrap>
              </ModalOtpContainer>
              <ModalNumberWrap>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '1')}>1</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '2')}>2</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '3')}>3</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '4')}>4</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '5')}>5</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '6')}>6</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '7')}>7</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '8')}>8</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '9')}>9</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp("")} className='reset'>Reset</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp + '0')}>0</ModalNumber>
                <ModalNumber onClick={()=>setModalOtp(modalOtp.slice(0, -1))} className='backspace'>
                  <img src='./../img/backspace.png' />
                </ModalNumber>
              </ModalNumberWrap>
              <ModalBtn onClick={otpModalBtn}>확인</ModalBtn>
            </ModalWrap>
          </ModalContainer>
        </>
      }
      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>Request Withdrawal</Title>
          <ContentTitle>출금 신청</ContentTitle>
          <ContentRow>
            <ContentRowTitle>보유수량</ContentRowTitle>
            <ContentCnt>
              <p>{nowPoint ? addCommasToNumber(nowPoint) : '0.0000'}</p>
              <p>ESP</p>
            </ContentCnt>
          </ContentRow>
          <ContentRow className='application_cnt'>
            <ContentRowTitle className='application_cnt'>신청수량</ContentRowTitle>
            <ContentInputContainer>
              <ContentInputWrap onClick={handleCntInput}>
                <ContentInput readOnly />
                <ContentInputTxt>
                  <p>{addCommasToNumber(cnt)}</p>
                  <span>ESP</span>
                </ContentInputTxt>
              </ContentInputWrap>
              <ContentBtn onClick={maxCnt}>최대수량</ContentBtn>
            </ContentInputContainer>
          </ContentRow>
          <ContentRow>
            <ContentRowTitle className='application_cnt'>출금방법</ContentRowTitle>
            <ContentSelect $isOpen={!isOpen} onClick={()=>{setIsOpen(!isOpen)}}>
              <ContentSelectTxt>{method}</ContentSelectTxt>
              <img src={isOpen ? './../img/select_up.png' : './../img/select_down.png'} />
              {
                isOpen &&
                <ContentSelectOptionBox>
                  {
                    data?.isWithdrawGmmt === "0" ? 
                    <ContentSelectOption onClick={handleOnChangeSelectValue} value="ESP">ESP</ContentSelectOption>
                    :
                    <>
                    <ContentSelectOption onClick={handleOnChangeSelectValue} value="GMMT">GMMT</ContentSelectOption>
                    <ContentSelectOption onClick={handleOnChangeSelectValue} value="ESP">ESP</ContentSelectOption>
                    </>

                  }
                </ContentSelectOptionBox>
              }
            </ContentSelect>
          </ContentRow>
          <ContentRow>
            <ContentRowTitle>지갑주소</ContentRowTitle>
            <ContentRowTxt>{data?.walletAddress ? data?.walletAddress : '지갑주소가 존재하지 않습니다.'}</ContentRowTxt>
          </ContentRow>
          <ContentTitle className='auth'>인증</ContentTitle>
          <ContentRow>
            <ContentRowTitle>구글 OTP</ContentRowTitle>
            <ContentInputWrap>
              <ContentInput value={otp} onClick={handleOtpInput} readOnly placeholder='구글 OTP 코드 입력' type={eye[0] ? 'password' : 'text'} />
              <img src={eye[0] ? './../img/common_viewoff_ico@3x.png' : './../img/common_view_ico@3x.png'} onClick={()=>{toggleEye(0)}} />
            </ContentInputWrap>
          </ContentRow>
          <SubmitWrap>
            <SubmitBtn onClick={handleSubmit}>Subimit Request</SubmitBtn>
          </SubmitWrap>
        </MainLayout>
        <Footer />
      </LayoutBg>
    </>
  )
}

export default RequestWithdrawal